import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet';
import Home from '../components/Misc/Home';
import NavBar from '../components/Misc/NavBar';
import Messenger from '../components/Misc/Messenger';
import { SUBMIT_EMAIL } from '../queries';

import data from '../static/headerData.json';

const HomeContainer = props => {
  const { googleButton } = props;

  const [snackObj, setSnackObj] = React.useState(null);
  const [submitEmail] = useMutation(SUBMIT_EMAIL);

  const handleSubmitEmail = async email => {
    // console.log(email);
    const resp = await submitEmail({ variables: { input: { email } } });

    if (resp && resp.data && resp.data.submitEmail.status === 'SUCCESS') {
      // setSnackObj({ status: 'SUCCESS', message: 'Email Saved!' });
      return true;
    } else {
      setSnackObj({
        status: 'ERROR',
        message: 'Error saving email. Please try again.',
      });
      return false;
    }
  };

  const handleSnackClose = () => {
    setSnackObj(null);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <NavBar fixed={true} transparent={true} hideTitle />
      <Home googleButton={googleButton} onSubmitEmail={handleSubmitEmail} />
      <Messenger snackObj={snackObj} onSnackClose={handleSnackClose} />
    </React.Fragment>
  );
};

HomeContainer.propTypes = {
  googleButton: PropTypes.element,
};

export default HomeContainer;
