import { useEffect, useRef } from 'react';
import Build from '../build.json';
import history from '../history';
import ky from 'ky';

// import tableToCsv from 'node-table-to-csv';
import XLSX from 'xlsx';
import { func } from 'prop-types';
import { rules } from 'eslint-config-prettier';

export function getSunday(ISODate) {
  let date = new Date(ISODate);

  const daysPastSunday = date.getDay();

  // get sunday based on request day
  date.setHours(-24 * daysPastSunday);

  // format YYMMDD
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');

  return year + month + day;
}

export function getDate(YYMMDD) {
  const year = YYMMDD.substring(0, 2);
  let month = YYMMDD.substring(2, 4);
  const day = YYMMDD.substring(4, 6);
  let date = new Date(2000 + Number(year), Number(month) - 1, Number(day));

  return date;
}

export function formatSunday(date) {
  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');

  return year + month + day;
}

export function getPrevSunday(YYMMDD) {
  const date = getDate(YYMMDD);
  date.setHours(-7 * 24);
  return formatSunday(date);
}

export function getNextSunday(YYMMDD) {
  const date = getDate(YYMMDD);
  date.setHours(7 * 24);
  return formatSunday(date);
}

export function getReadableDate(YYMMDDOrDate, suppressYear) {
  const date =
    YYMMDDOrDate.length === 6 ? getDate(YYMMDDOrDate) : new Date(YYMMDDOrDate);
  const monthNumb = Number((date.getMonth() + 1).toString().padStart(2, '0'));

  let month = '';

  switch (monthNumb) {
    case 1:
      month = 'Jan';
      break;
    case 2:
      month = 'Feb';
      break;
    case 3:
      month = 'Mar';
      break;
    case 4:
      month = 'Apr';
      break;
    case 5:
      month = 'May';
      break;
    case 6:
      month = 'Jun';
      break;
    case 7:
      month = 'Jul';
      break;
    case 8:
      month = 'Aug';
      break;
    case 9:
      month = 'Sep';
      break;
    case 10:
      month = 'Oct';
      break;
    case 11:
      month = 'Nov';
      break;
    case 12:
      month = 'Dec';
      break;
    default:
      console.error('error with getReadableDate');
  }

  const year = date
    .getFullYear()
    .toString()
    .slice(-2);
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');

  let returnStr = month + ' ' + day;
  if (!suppressYear) returnStr = returnStr.concat(', 20' + year);

  return returnStr;
}

export function getReadableMonth(date) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
  });
}

export function getHoursWeek(YYMMDD) {
  let sat = getDate(YYMMDD);
  sat.setHours(6 * 24);
  sat = formatSunday(sat);
  return getReadableDate(YYMMDD, true) + '-' + getReadableDate(sat);
}

export function valToHex(val) {
  const scaledVal = Math.trunc(val * 255);
  return scaledVal.toString(16);
}

export function downloadFile(data, fileName, type = 'text/plain') {
  // Create an invisible A element
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(new Blob([data], { type }));

  // Use download attribute to set set desired file name
  a.setAttribute('download', fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export function formatCurrency(number, noDecimal) {
  if (isNaN(number)) return '-';
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  // right out millions
  if (number / 10000000 > 1) return `$${Math.round(number / 1000000)} million`;

  if (noDecimal)
    return currencyFormatter.format(Math.round(number)).replace(/\.00/g, '');
  else return currencyFormatter.format(number);
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function digestMutateResponse(res, type) {
  // console.log(res);
  let key = type + 's'; // may need a workaround here
  if (type === 'hours') key = 'hours';
  if (type === 'wiki') key = 'wiki';
  if (type === 'lead') key = 'leadPost';
  if (type === 'unsubmitHours') key = 'hours';
  let snackObj = { status: 'SUCCESS', message: 'Saved' };
  if (res.errors) {
    console.error(res.errors);
    snackObj = {
      status: 'ERROR',
      message: 'Error Saving',
    };
    return { snackObj, item: null };
  }
  if (res.data.deleteLead) {
    return { snackObj: { status: 'SUCCESS', message: 'Lead Deleted' } };
  }

  if (res.data.processLead && !res.data.processLead.errors) {
    return { snackObj: { status: 'SUCCESS', message: 'Lead Processed' } };
  }

  if (res.data.unsubmitTimesheet && !res.data.unsubmitTimesheet.errors) {
    return {
      snackObj: { status: 'SUCCESS', message: 'Timesheet Unsbumitted' },
    };
  }

  if (res.data.updateBacklog && !res.data.updateBacklog.errors) {
    return {
      snackObj: { status: 'SUCCESS', message: 'Backlog Updated' },
    };
  }

  if (!res.data || !res.data.mutateItem) {
    console.error(`Error! Server Data Mutation falied`);
    snackObj = { status: 'ERROR', message: 'Error Saving' };
    return { snackObj, item: null };
  }

  const { mutateItem } = res.data;

  // catch delete response
  if (mutateItem.deleted) return { status: 'SUCCESS', message: 'Deleted' };

  if (mutateItem.errors?.length > 0) {
    console.error(res.data.mutateItem.errors[0].message);
    snackObj = { status: 'ERROR', message: 'Error Saving' };
    return { snackObj, item: null };
  }

  if (type === 'wiki') {
  }

  if (type === 'organization')
    return { snackObj, item: res.data.mutateItem.organization };

  if (!res.data.mutateItem[key]) return { snackObj, item: null };
  return { snackObj, item: res.data.mutateItem[key][0] };
}

export function getResourceRateById(id, resourceTypes, organization) {
  let rate = null;
  resourceTypes.forEach(resourceType => {
    if (resourceType.id === id) {
      const multiplier = resourceType.freelance ? 1 : organization.multiplier;
      rate = (resourceType.salary * multiplier) / (52 * 40);
    }
  });
  return rate;
}

export function getItemById(id, array) {
  // console.log(id);
  // console.log(array);
  let retObj = null;
  array.forEach(entry => {
    if (entry.id === id) retObj = entry;
  });
  return retObj;
}

export function getResourceTypeById(id, resourceTypes) {
  let retObj = { error: 'no resource type found' };
  resourceTypes.forEach(resourceType => {
    if (id === resourceType.id) retObj = resourceType;
  });
  return JSON.parse(JSON.stringify(retObj));
}

export function getStaffMemberById(id, resources) {
  let retObj = { error: 'no staff member found' };
  resources.forEach(resources => {
    if (id === resources.id) retObj = resources;
  });
  return JSON.parse(JSON.stringify(retObj));
}

export function getProjectById(id, projects) {
  let retObj = { error: 'no project found' };
  projects.forEach(proj => {
    if (id === proj.id) retObj = proj;
  });
  return retObj;
}

export function sortArrayByTwoProps(array, prop1, prop2) {
  return array.sort(function(a, b) {
    if (a[prop1] === b[prop1]) {
      // Price is only important when cities are the same
      return b[prop2] - a[prop2];
    }
    return a[prop1] > b[prop1] ? 1 : -1;
  });
}

export function genNegativeStyle(value, threshold) {
  if (isNaN(value)) return {};
  else if (value < (threshold || 0)) return { color: 'red' };
  else return {};
}

export function formatValue(value, format, round) {
  let sigDigits = 2;
  if (round) sigDigits = 0;
  if (value === -0) value = 0;
  // console.log(format);
  // console.log(isNaN(value));
  // console.log('-------');
  if (format !== 'string' && isNaN(value)) {
    // console.log(value);
    return '-';
  }
  switch (format) {
    case 'currency':
      return formatCurrency(value, true);
    case '%':
      if (value === Infinity || value === -Infinity) return '-';
      return (value * 100).toFixed(sigDigits) + '%';
    default:
      if (value === 0) return '-';
      return value.toFixed(sigDigits);
  }
}

export function downloadTable(id, params) {
  const table = global.document.getElementById(id);
  if (!params) params = {};
  let { type, filename } = params;
  if (!type) type = 'xlsx';
  if (!filename) filename = id + '.' + type;
  else filename = filename + '.' + type;

  const wb = XLSX.utils.table_to_book(table, { sheet: id });
  XLSX.write(wb, { bookType: type, type: 'base64' });
  XLSX.writeFile(wb, filename, {});
  console.log(wb);
  // return dl ?
  //   XLSX.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
  //   XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
}

export function checkBuild(build) {
  const version = Number(Build.frontEnd.split('.').pop());
  const serverVersion = Number(build.frontEnd.split('.').pop());
  if (version < serverVersion) {
    console.log('build is not current. Should reload.');
    return {
      status: 'WARNING',
      message: 'New Update Available. Please Reload this page.',
    };
  } else return null;
}

export function getLocaleDate(dateInput) {
  return new Date(dateInput).toLocaleString('en-US', { dateStyle: 'short' });
}

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getDaysInMonth(month, year) {
  return new Date(year, month + 1, 0).getDate();
}

export function getRelativeDate(dateISO) {
  const formatter = new Intl.RelativeTimeFormat('en-US', {
    numeric: 'auto',
  });

  const delta = new Date(dateISO) - new Date();

  const minutes = delta / (1000 * 60);
  const hours = minutes / 60;
  const days = hours / 24;
  const weeks = days / 7;
  const months = days / 30;

  if (months < -1) return formatter.format(Math.round(months), 'months');
  if (weeks < -1) return formatter.format(Math.round(weeks), 'weeks');
  if (days < -1) return formatter.format(Math.round(days), 'days');
  if (hours < -1) return formatter.format(Math.round(hours), 'hours');
  if (minutes < -1) return formatter.format(Math.round(minutes), 'minutes');
}

export function getPhaseCompleteStatus(phase, dateISO) {
  const time = dateISO ? new Date(dateISO).getTime() : new Date().getTime();

  const weeksElapsed =
    (time - new Date(phase.startDate).getTime()) / (1000 * 60 * 60 * 24 * 7);

  if (weeksElapsed <= 0) return 0;
  if (weeksElapsed > phase.duration) return 1;
  return weeksElapsed / phase.duration;
}

export function getProjectCompleteStatus(project, dateISO) {
  const time = dateISO ? new Date(dateISO).getTime() : new Date().getTime();
  let startTime = Math.min(
    ...project.phases.map(phase => new Date(phase.startDate).getTime())
  );
  let endTime = Math.max(
    ...project.phases.map(phase =>
      !phase.duration
        ? 0
        : new Date(phase.startDate).getTime() +
          phase.duration * 7 * 24 * 60 * 60 * 1000
    )
  );

  const timeElapsed = time - startTime;

  if (timeElapsed <= 0) return 0;
  if (timeElapsed > endTime) return 1;
  return timeElapsed / (endTime - startTime);
}

export function addEnumerationsToResources(resources) {
  let ids = {};

  resources.forEach((resource, index) => {
    if (!ids[resource.id]) {
      ids[resource.id] = [index];
    } else {
      ids[resource.id].push(index);
      resource.name = resource.name + ' (' + ids[resource.id].length + ')';
    }
  });

  // console.log(ids);

  return resources;
}

export function UUID(length) {
  return new Array(length)
    .fill('x')
    .join('')
    .replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
}

export function addOpacityToCSS(color, opacity) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
}

export function genShortName(name) {
  return name
    .split(' ')
    .map(n => n[0])
    .join('');
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function isUserInProjectTeam(userId, project) {
  let returnVal = false;

  project.team.forEach(teamMember => {
    if (teamMember.id === userId) returnVal = true;
  });
  return returnVal;
}

export function getLocalLink(url) {
  if (url.includes(window.location.origin))
    return url.replace(window.location.origin, '');
  return null;
}

export function handleClickLink(url, ev) {
  const localLink = getLocalLink(url);
  if (localLink) {
    console.log(localLink);
    history.push(localLink);
  } else {
    window.open(url, '_blank').focus();
  }
}

export async function uploadFileToS3(file, presignedPost, { id }) {
  const finalFileURL = `${presignedPost.url}/${presignedPost.fields.key}`;
  let formData = new FormData();

  // console.log(file, presignedPost);

  formData.append('x-amz-meta-user-id', id);
  formData.append('Content-Type', file.type);

  Object.entries(presignedPost.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });

  formData.append('file', file);

  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ', ' + pair[1]);
  // }

  try {
    const resp = await ky.post(presignedPost.url, {
      body: formData,
    });

    console.log(resp);
    return { url: finalFileURL };
  } catch (e) {
    console.error(e);
    return { errors: { message: 'Error uploading file' } };
  }
}

export const resizeImage = (file, maxDimension) => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const { width, height } = img;
        const newWidth =
          width > height ? maxDimension : (width / height) * maxDimension;
        const newHeight =
          height > width ? maxDimension : (height / width) * maxDimension;
        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        ctx.canvas.toBlob(blob => resolve(blob), 'image/jpeg', 1);
      };
    };
  });
};
