import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  //   items: [],
  currentTimesheet: null,
  loading: false, // not used yet
  provisionalRow: null,
  relevantTasks: [],
  unRowedRelevantTasks: [],
};

export const timesheetsSlice = createSlice({
  name: 'timesheets',
  initialState,
  reducers: {
    setCurrentTimesheet: (state, action) => {
      // console.log('redux timesheet updating');
      state.currentTimesheet = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setProvisionalRow: (state, action) => {
      state.provisionalRow = action.payload;
    },
    setRelevantTasks: (state, action) => {
      state.relevantTasks = action.payload;
    },
    setUnRowedRelevantTasks: (state, action) => {
      state.unRowedRelevantTasks = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentTimesheet,
  setLoading,
  setProvisionalRow,
  setRelevantTasks,
  setUnRowedRelevantTasks,
} = timesheetsSlice.actions;

export default timesheetsSlice.reducer;
