import React, { useEffect, useState, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  setNewlySavedTask,
  setBacklogTasks,
  setCurrentTask,
  setTasks,
} from '../redux/tasks/tasksSlice';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  QUERY_ITEMS,
  MUTATE_ITEM,
  GET_BACKLOG,
  MUTATION_UPDATE_BACKLOG,
} from '../queries';
import { getToken } from '../actions/UserActions';
import { digestMutateResponse } from '../actions/BaseActions';
import { Task, removeResidue, serializeObject } from '../classes';
import { TASK_STATUSES } from '../constants';
import { useLocation } from 'react-router-dom';
import equal from 'deep-equal';
import useMessages from './useMessages';
import history from '../history';

const useTasks = () => {
  const { items, backlogItems, newlySavedTask, currentTask } = useSelector(
    state => state.tasks
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const { setConfirmMessage, setOnConfirm, setSnack } = useMessages();

  // Query
  const [fetchTasks, { called, loading, data, refetch }] = useLazyQuery(
    QUERY_ITEMS,
    {
      variables: {
        input: {
          token: getToken(),
          requestedItems: ['tasks'],
        },
      },
    }
  );

  // Query Backlog
  const [
    fetchBacklogTasks,
    {
      called: backlogFetched,
      loading: backlogLoading,
      data: backlogData,
      refetch: refetchBacklog,
    },
  ] = useLazyQuery(GET_BACKLOG, {
    variables: {
      input: {
        token: getToken(),
      },
    },
  });

  // update tasks on change
  useEffect(() => {
    if (data?.queryItems.tasks) {
      dispatch(setTasks(data?.queryItems.tasks));
    }
  }, [data]);

  // update backlog on change
  useEffect(() => {
    if (
      backlogData?.getBacklog.backlogTasks &&
      !equal(backlogData.getBacklog.backlogTasks, backlogItems)
    ) {
      dispatch(setBacklogTasks(backlogData?.getBacklog.backlogTasks));
    }
  }, [backlogData, backlogItems]);

  // Mutations
  const [mutateItem] = useMutation(MUTATE_ITEM);
  const [mutateBacklog] = useMutation(MUTATION_UPDATE_BACKLOG);

  const updateBacklog = async taskArray => {
    if (taskArray.length === 0) return;

    // update backlog tasks first
    dispatch(
      setBacklogTasks(backlogItems.filter(task => !taskArray.includes(task)))
    );

    dispatch(
      setTasks([
        ...items,
        ...taskArray.map(task => ({ ...task, status: 0 })), // update status locally
      ])
    );

    const taskIdsToUnBacklog = taskArray.map(task => task.id);
    const resp = await mutateBacklog({
      variables: {
        input: {
          token: getToken(),
          taskIdsToUnBacklog,
        },
      },
    });

    const { snackObj } = digestMutateResponse(resp, 'backlog');
    setSnack(snackObj);
  };

  const updateTask = async task => {
    // test deep equals - don't save if they are equal
    if (task.id) {
      const prevTask = items.find(a => a.id === task.id);
      if (equal(task, prevTask)) return;
    }

    // allows task to be saved to redux
    task = serializeObject(new Task(task));

    // update tasks to show in UI first
    // if task is going to backlog, move it
    if (task.status === -1) {
      dispatch(setTasks([...items.filter(a => a.id && a.id !== task.id)]));
    } else {
      dispatch(
        setTasks([...items.filter(a => a.id && a.id !== task.id), task])
      );
    }

    // check to see if it's saved in the database already
    const isNew = task.id ? false : true; // redundant, but keep for now

    // update in the database
    const input = {
      token: getToken(),
      task: removeResidue(task),
      action: isNew ? 'ADD' : 'MUTATE',
    };

    const resp = await mutateItem({
      variables: { input },
    });

    // handle messaging
    const { snackObj, item: savedTask } = digestMutateResponse(resp, 'task');
    if (snackObj) dispatch(setSnack(snackObj));

    // handle error
    if (!savedTask)
      dispatch(setSnack({ status: 'ERROR', message: 'Error saving task' }));
    else {
      // replace task with new saved one (if not saved to backlog)
      if (savedTask.status !== -1) {
        dispatch(
          setTasks([
            ...items.filter(a => a.id && a.id !== savedTask.id),
            savedTask,
          ])
        );

        // keep dialog open if it is open
        if (history.location.pathname.includes(savedTask.id)) {
          dispatch(setNewlySavedTask(savedTask));
        }
      }
    }
  };

  const deleteTask = async task => {
    setConfirmMessage('Are you sure you want to delete this task?');

    setOnConfirm(async () => {
      // update UI first
      dispatch(setTasks([...items.filter(a => a.id !== task.id)]));

      const resp = await mutateItem({
        variables: {
          input: {
            token: getToken(),
            task: removeResidue(serializeObject(new Task(task))),
            action: 'DELETE',
          },
        },
      });

      if (resp?.data?.mutateItem?.deleted) {
        setSnack({ status: 'SUCCESS', message: 'Task deleted.' });
        history.replace('/tasks');
      } else {
        setSnack({ status: 'ERROR', message: 'Error Deleting Task!' });
      }
    });
  };

  const createEmptyTask = ({ projectId, userId, assignee, phaseId }) => {
    // strips the class for redux compatibility
    const emptyTask = JSON.parse(
      JSON.stringify(
        new Task({
          projectId,
          userId,
          assignee,
          phaseId,
          status: 0,
        })
      )
    );
    dispatch(setTasks([...items, emptyTask]));
    dispatch(setCurrentTask(emptyTask));
  };

  return {
    items,
    backlogItems,
    backlogFetched,
    updateBacklog,
    currentTask,
    setCurrentTask: task => dispatch(setCurrentTask(task)),
    refetchBacklog,
    updateTask,
    deleteTask,
    fetchTasks,
    fetchBacklogTasks,
    refetch: () => {
      console.log('refetching');
      refetch();
    },
    createEmptyTask,
    statuses: TASK_STATUSES,
    loading: false, // fix this later
    newlySavedTask,
    urlTaskId: location.pathname.replaceAll('/', '').replace('tasks', ''),
  };
};

export default useTasks;
