import React, { useEffect, useState } from 'react';
import useProjects from './useProjects';
import useTasks from './useTasks';
import useUsers from './useUsers';
import useTimesheets from './useTimesheets';
import useSiteReports from './useSiteReports';
import useMessages from './useMessages';
import useOrganization from './useOrganization';
import { useSelector, useDispatch } from 'react-redux';
import { setSnack } from '../redux/messages/messagesSlice';
import { setUser } from '../redux/user/userSlice';
import { initialize, setLoading } from '../redux/status/statusSlice';

const useData = props => {
  const tasks = useTasks();
  const projects = useProjects();
  const users = useUsers();
  const messages = useMessages();
  const timesheets = useTimesheets();
  const siteReports = useSiteReports();
  const org = useOrganization();
  const [confirmMessage, setConfirmMessage] = useState('');
  const [onConfirm, setOnConfirm] = useState(() => {});

  const {
    user: { value: user },
    organization: { value: organization },
    status: { initialized, loading },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  // handle Initialization
  useEffect(() => {
    if (initialized || !user) return;
    console.log('initalizing data');
    dispatch(initialize());
    tasks.fetchTasks();
    users.fetchUsers();
    projects.fetchProjects();
    siteReports.fetchSiteReports();
    org.fetchOrganization();
  }, [user, initialized]);

  useEffect(() => {
    if (
      tasks.loading ||
      users.loading ||
      projects.loading ||
      siteReports.loading
    ) {
      if (!loading) dispatch(setLoading(true));
    } else if (loading) {
      dispatch(setLoading(false));
    }
  }, [
    tasks.loading,
    users.loading,
    projects.loading,
    siteReports.loading,
    loading,
  ]);

  const refetch = () => {
    console.log('should refresh all data now');
  };

  return {
    user,
    loading,
    tasks,
    projects,
    users,
    messages,
    timesheets,
    siteReports,
    organization,
    setUser: user => dispatch(setUser(user)),
    refetch,
  };
};

export default useData;
