import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  backlogItems: [],
  currentTask: null,
  newlySavedTask: null,
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    addTask: state => {},
    deleteTask: state => {},
    updateTask: state => {},
    setTasks: (state, action) => {
      state.items = action.payload;
    },
    setCurrentTask: (state, action) => {
      state.currentTask = action.payload;
    },
    setBacklogTasks: (state, action) => {
      state.backlogItems = action.payload;
    },
    setNewlySavedTask: (state, action) => {
      state.newlySavedTask = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addTask,
  deleteTask,
  setTasks,
  setCurrentTask,
  setBacklogTasks,
  setNewlySavedTask,
} = tasksSlice.actions;

export default tasksSlice.reducer;
