import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
