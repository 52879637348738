import { configureStore } from '@reduxjs/toolkit';
import tasksSlice from './tasks/tasksSlice';
import usersSlice from './users/usersSlice';
import userSlice from './user/userSlice';
import projectsSlice from './projects/projectsSlice';
import messagesSlice from './messages/messagesSlice';
import statusSlice from './status/statusSlice';
import timesheetsSlice from './timesheets/timesheetsSlice';
import siteReportsSlice from './siteReports/siteReportsSlice';
import organizationSlice from './organization/organizationSlice';

export const store = configureStore({
  reducer: {
    tasks: tasksSlice,
    users: usersSlice,
    user: userSlice,
    projects: projectsSlice,
    messages: messagesSlice,
    status: statusSlice,
    timesheets: timesheetsSlice,
    siteReports: siteReportsSlice,
    organization: organizationSlice,
  },
});
