import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink, Link } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

import { signOut, getToken } from '../../actions/UserActions';
import theme from '../../theme';
import history from '../../history';

// import { createScene } from '../actions/threeActions';

// define inline style based on theme

const useStyles = makeStyles(() => ({
  root: {
    // padding: theme.spacing(3),
    // width: '100vw',
    // height: '100vh',
  },
  appBar: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: 'none',
    '& a': {
      color: '#000',
    },
  },
  toolbar: {
    // paddingLeft: '14px',
    // paddingRight: '14px',
    // justifyContent: 'space-between'
  },
  title: {
    textDecoration: 'none',
  },
  spacer: {
    flex: 1,
  },
  current: {
    fontWeight: 700,
    textDecoration: 'none',
    pointerEvents: 'none',
  },
}));

const genStyle = props => {
  if (props.transparent) return { backgroundColor: 'transparent' };
  else return {};
};

const navLinks = {
  superuser: ['Leads'], // only for superuser, etc.
  admin: ['Projects', 'Staffing', 'Finances'],
  employee: ['Wiki', 'Tasks', 'Timesheet', 'Site Reports'],
};

// replace spaces in link with dashes
const urlifyLink = link => {
  return link.toLowerCase().replace(/ /g, '-');
};

const NavBar = props => {
  const { user, pageName, confirmMsg, fixed, hideTitle } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showGoogleDialog, setShowGoogleDialog] = React.useState(false);

  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const { pathname } = history.location;

  const dialogOpen = confirmMsg && confirmMsg.length > 0 ? true : false;

  const closeSnack = () => {
    setTimeout(() => {
      props.onSnackClose();
    }, 1000); // to give time for the animation
  };

  const handleMenuClick = ev => {
    setAnchorEl(ev.currentTarget);
  };

  const handleMenuClose = ev => {
    const { id } = ev.target;
    if (id === 'logout') signOut();
    else if (id === 'login') setShowGoogleDialog(true);
    else if (id === 'token') console.log(getToken());
    setAnchorEl(null);
  };

  const cancelGoogleDialog = () => {
    setShowGoogleDialog(false);
  };

  const position = fixed ? 'fixed' : 'static';

  return (
    <>
      <AppBar
        position={position}
        className={classes.appBar}
        style={genStyle(props)}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <Grid container direction="row" spacing={2} alignItems="center">
            <Grid item>
              <Typography variant="h6">
                <Link to="/" className={classes.title}>
                  {hideTitle ? '' : 'Design Mainframe'}
                </Link>
                {pageName ? ' | ' + pageName : ''}
              </Typography>
            </Grid>
            <Grid item className={classes.spacer}>
              <span />
            </Grid>

            {/* ----- LINKS ----- */}
            {!mdDown && user && (
              <>
                {Object.keys(navLinks).map(role => {
                  if (user.roles.includes(role.toLowerCase()))
                    return (
                      <React.Fragment key={role}>
                        {navLinks[role].map(key => (
                          <Grid item key={key}>
                            <Typography>
                              <NavLink
                                exact
                                to={`/${urlifyLink(key.toLowerCase())}`}
                                activeClassName={classes.current}
                              >
                                {key}
                              </NavLink>
                            </Typography>
                          </Grid>
                        ))}
                      </React.Fragment>
                    );
                })}
              </>
            )}

            <Grid item>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleMenuClick}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* NAV MENU */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {false && <MenuItem onClick={handleMenuClose}>My account</MenuItem>}
        {user && (
          <MenuItem component={Link} to={'/'} disabled={pathname === '/'}>
            Home
          </MenuItem>
        )}
        {user &&
          Object.keys(navLinks).map(role => {
            if (user.roles.includes(role.toLowerCase()))
              return navLinks[role].map(key => (
                <MenuItem
                  component={Link}
                  key={key}
                  disabled={pathname.toLowerCase() === `/${key.toLowerCase()}`}
                  to={`/${urlifyLink(key.toLowerCase())}`}
                >
                  {key}
                </MenuItem>
              ));
          })}

        {user && (
          <MenuItem id="logout" onClick={handleMenuClose}>
            Logout
          </MenuItem>
        )}

        {!user && (
          <MenuItem id="login" onClick={handleMenuClose}>
            Log In With Google
          </MenuItem>
        )}
      </Menu>

      {/* GOOGLE DIALOG */}
      <Dialog
        onClose={cancelGoogleDialog}
        open={showGoogleDialog}
        maxWidth="xs"
      >
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
          buttonText="Login"
          onSuccess={global.context.handleGoogleResponse}
          onFailure={global.context.handleGoogleResponse}
          cookiePolicy={'single_host_origin'}
        />
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  // required for style system to work
  pageName: PropTypes.string,
  snackObj: PropTypes.object,
  onSnackClose: PropTypes.func,
  confirmMsg: PropTypes.string,
  cancelDialog: PropTypes.func,
  confirmDialog: PropTypes.func,
  user: PropTypes.object,
  googleButton: PropTypes.element,
  fixed: PropTypes.bool,
  transparent: PropTypes.bool,
  hideTitle: PropTypes.bool,
  // showSnack: PropTypes.bool
};

export default NavBar;
