import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setConfirmMessage,
  setSnack,
  clearConfirmMessage,
  clearSnack,
} from '../redux/messages/messagesSlice';

const useMessages = () => {
  const { snackObj, confirmMessage } = useSelector(state => state.messages);
  const dispatch = useDispatch();

  return {
    // snack
    snackObj,
    setSnack: snackObject => dispatch(setSnack(snackObject)),
    clearSnack: () => dispatch(clearSnack()),
    // dialog
    confirmMessage,
    setConfirmMessage: message => dispatch(setConfirmMessage(message)),
    setOnConfirm: func => {
      // fix this later
      global.onDialogConfirm = func;
    },
    setOnCancel: func => {
      global.onDialogCancel = func;
    },
    onConfirm: () => {
      global.onDialogConfirm();
      dispatch(clearConfirmMessage());
      delete global.onDialogConfirm;
      delete global.onDialogCancel;
    },

    onCancel: () => {
      if (global.onDialogCancel) global.onDialogCancel();
      dispatch(clearConfirmMessage());
      delete global.onDialogConfirm;
      delete global.onDialogCancel;
    },
  };
};

export default useMessages;
