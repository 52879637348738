import React, { useEffect, useState } from 'react';
import {
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import deepEqual from 'deep-equal';
import { SNACK_HIDE_DURATION } from '../../constants';

import useData from '../../hooks/useData';

const sx = {
  root: {
    color: '#000',
    // padding: theme.spacing(3),
    // width: '100vw',
    // height: '100vh',
  },
};

const genSnackStyle = snackObj => {
  if (snackObj) {
    if (snackObj.status === 'error') return { backgroundColor: 'red' };
    else if (snackObj.status === 'success') return {};
  } else return {};
};

const Messenger = props => {
  const {
    snackObj: propSnackObj,
    // confirmMsg,
    cancelDialog,
    confirmDialog,
  } = props;

  const {
    messages: {
      confirmMessage,
      onConfirm,
      onCancel,
      clearSnack,
      snackObj: hookSnackObj,
    },
  } = useData();

  const confirmMsg = props?.confirmMsg || confirmMessage;

  const [snackObj, setSnackObj] = useState(propSnackObj);

  useEffect(() => {
    if (deepEqual(hookSnackObj, snackObj)) return;
    setSnackObj(hookSnackObj);
  }, [hookSnackObj, snackObj]);

  const dialogOpen = Boolean(confirmMsg);

  const closeSnack = () => {
    setTimeout(() => {
      if (props.onSnackClose) props.onSnackClose();
      clearSnack();
    }, 1000); // to give time for the animation
  };

  const reduxHandleConfirm = () => {
    if (onConfirm) onConfirm();
  };

  const reduxHandleCancel = () => {
    onCancel();
  };

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={Boolean(snackObj?.message)}
        autoHideDuration={SNACK_HIDE_DURATION}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        onClose={closeSnack}
        style={genSnackStyle(snackObj)}
        message={
          <Box id="message-id" sx={sx.root}>
            {snackObj && snackObj.message ? snackObj.message : ''}
          </Box>
        }
      />

      {/* GLOBAL DIALOG FOR CONFIRMATION MESSAGING */}
      <Dialog onClose={cancelDialog} open={dialogOpen} maxWidth="xs">
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDialog || reduxHandleCancel}>Cancel</Button>
          <Button onClick={confirmDialog || reduxHandleConfirm}>OK</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Messenger;
