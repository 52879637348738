import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setCurrentTimesheet,
  setLoading,
  setProvisionalRow,
  setRelevantTasks,
  setUnRowedRelevantTasks,
} from '../redux/timesheets/timesheetsSlice';
import useMessages from './useMessages';
import { digestMutateResponse } from '../actions/BaseActions';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_ITEMS, MUTATE_ITEM } from '../queries';
import { getToken } from '../actions/UserActions';
import { Hours } from '../classes';
import useTasks from './useTasks';

const useTimesheets = () => {
  const {
    timesheets: {
      currentTimesheet,
      loading,
      provisionalRow,
      relevantTasks,
      unRowedRelevantTasks,
    },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const {
    setSnack,
    setConfirmMessage,
    setOnConfirm,
    setOnCancel,
  } = useMessages();

  const { item: tasks } = useTasks();

  const [sunday, setSunday] = useState(null);

  const [
    mutateItem,
    { loading: mutateLoading, called: mutateCalled },
  ] = useMutation(MUTATE_ITEM);

  // Query
  const [
    fetchCurrentTimesheet,
    { called, loading: lazyLoading, data, refetch },
  ] = useLazyQuery(QUERY_ITEMS, {
    variables: {
      input: {
        token: getToken(),
        requestedItems: ['hours'],
      },
    },
  });

  // update timesheet on fetch
  useEffect(() => {
    if (data?.queryItems?.hours?.length === 1) {
      dispatch(setCurrentTimesheet(data.queryItems.hours[0]));
    }
  }, [data]);

  // update current timesheet on sunday change
  useEffect(async () => {
    if (!sunday) return;
    if (currentTimesheet?.sunday === sunday) return;
    dispatch(setLoading(true));
    await fetchCurrentTimesheet({
      variables: {
        input: {
          token: getToken(),
          requestedItems: ['hours'],
          sunday,
        },
      },
    });
    dispatch(setLoading(false));
  }, [sunday, currentTimesheet]);

  // Process loading
  useEffect(() => {
    if (!called) return; // removes instances that never call it from the hook
    if (lazyLoading === loading) return;
    dispatch(setLoading(lazyLoading));
  }, [lazyLoading, loading, called]);

  // update cell
  const updateCell = ({ id, field, value }) => {
    // console.log('not implemented yet');
    console.log('updating cell');
    console.log({ id, field, value });
    // console.log(currentTimesheet);

    dispatch(
      setCurrentTimesheet({
        ...currentTimesheet,
        rows: currentTimesheet.rows.map((row, rIndex) => {
          if (Number(id) === rIndex) {
            return {
              ...row,
              hours: row.hours.map((hourEntry, hIndex) => {
                if (Number(field) === hIndex) {
                  return { ...hourEntry, count: Number(value) };
                } else return hourEntry;
              }),
            };
          } else return row;
        }),
      })
    );
  };

  const updateHoursNote = ({ id, field, value }) => {
    // console.log('not implemented yet');
    // console.log({ id, field, value });
    dispatch(
      setCurrentTimesheet({
        ...currentTimesheet,
        rows: currentTimesheet.rows.map((row, rIndex) => {
          if (Number(id) === rIndex) {
            return {
              ...row,
              hours: row.hours.map((hourEntry, hIndex) => {
                if (Number(field) === hIndex) {
                  return { ...hourEntry, note: value };
                } else return hourEntry;
              }),
            };
          } else return row;
        }),
      })
    );
  };

  const deleteRow = index => {
    // wrap in setTimeout to deal with state racing issues
    setTimeout(() => {
      dispatch(
        setCurrentTimesheet({
          ...currentTimesheet,
          rows: currentTimesheet.rows.filter((row, rIndex) => index !== rIndex),
        })
      );
    }, []);
  };

  const addRow = row => {
    // wrap in setTimeout to deal with state racing issues
    setTimeout(() => {
      dispatch(setProvisionalRow(null));
      dispatch(
        setCurrentTimesheet({
          ...currentTimesheet,
          rows: [...currentTimesheet.rows, row],
        })
      );
    }, []);
  };

  const submitCurrentTimesheet = async () => {
    console.log('submitting timesheet');

    setConfirmMessage(
      'Are you sure you want to submit this timesheet? You will be unable to edit it after.'
    );

    setOnConfirm(() => {
      dispatch(setCurrentTimesheet({ ...currentTimesheet, submitted: true }));
    });
  };

  const saveCurrentTimesheet = async () => {
    // update in the database
    const input = {
      token: getToken(),
      hours: new Hours(currentTimesheet, { removeEmptyStrings: true }),
      action: 'UPDATE',
    };

    console.log('mutate input:', input);

    const resp = await mutateItem({
      variables: { input },
    });

    // handle messaging
    const { snackObj, item: savedTask } = digestMutateResponse(resp, 'hours');
    if (snackObj) setSnack(snackObj);
  };

  return {
    currentTimesheet,
    sunday: currentTimesheet?.sunday,
    setSunday,
    updateCell,
    updateHoursNote,
    saveCurrentTimesheet,
    submitCurrentTimesheet,
    deleteRow,
    addRow,
    provisionalRow,
    setProvisionalRow: row => dispatch(setProvisionalRow(row)),
    relevantTasks,
    setRelevantTasks: array => dispatch(setRelevantTasks(array)),
    unRowedRelevantTasks,
    setUnRowedRelevantTasks: array => dispatch(setUnRowedRelevantTasks(array)),
  };
};

export default useTimesheets;
