import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, Paper, Button, TextField } from '@mui/material';
import Loading from './Loading';
import { validateEmail } from '../../actions/BaseActions';

// define inline style based on theme
import theme from '../../theme';

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
  },
  subTagline: {
    paddingTop: theme.spacing(2),
  },
  listWrapper: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(4),
    '& h5': {
      marginBottom: theme.spacing(1),
    },
  },
  footer: {
    height: '75vh',
    padding: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  paper: {
    width: 350,
    [theme.breakpoints.down('md')]: {
      width: 'inherit',
    },
    padding: theme.spacing(6),
    border: '3px solid #000',
  },
  paperImg: {
    border: '3px solid #000',
  },
  textBanner: {
    maxWidth: '800px',
  },
  grid: {
    marginTop: theme.spacing(2),
  },
  banner: {
    // background: 'red',
    height: `calc(100vh - ${theme.spacing(1)})`,
    padding: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
      height: 'inherit',
    },
  },
  feature: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  bannerImage: {
    width: '100%',
  },
  submitButton: {
    paddingTop: '7px',
    paddingBottom: '7px',
  },
  link: {
    '& a': {
      color: '#000',
    },
  },
  beta: {},
}));

const signUp = props => {};

const Home = props => {
  const { onSubmitEmail } = props;
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [helperText, setHelperText] = React.useState('');
  const [submitStatus, setSubmitStatus] = React.useState('UNSUBMITTED');
  // const [submitStatus, setSubmitStatus] = React.useState('LOADING');
  // const [submitStatus, setSubmitStatus] = React.useState('SUBMITTED');

  const handleEmailChange = ev => {
    setEmail(ev.target.value);
    if (helperText) setHelperText('');
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) return setHelperText('email appears invalid');
    setSubmitStatus('LOADING');
    if (await onSubmitEmail(email)) {
      setEmail('');
      setSubmitStatus('SUBMITTED');
    }
  };

  const features = [
    {
      id: 'feature-proposals',
      headline: 'Generate Proposals',
      text:
        'Using our online tools, quickly prepare a Project Fee Proposal. No Excel required.',
      imgSrc: '/images/home_proposal.png',
      imgAlt: 'Fee Proposals made easy for Architects and Designers',
      short: 'Quickly Generate Fee Proposals',
    },
    {
      id: 'feature-schedules',
      headline: 'Create Schedules',
      text:
        'Quickly plan a project from start to finish with automated scheduling tools.',
      imgSrc: '/images/home_schedule.png',
      imgAlt: 'Scheduling Tools for Architects and Designers',
      short: 'Easily Create Project Schedules',
    },
    {
      id: 'feature-time',
      headline: 'Track Time',
      text: 'Track your employees time for insights (and invoices).',
      imgSrc: '/images/home_timesheet.png',
      imgAlt:
        'Time tracking and Timesheets for employees of Architects and Designers',
      short: "Track Employee's Time",
    },
    {
      id: 'feature-staff',
      headline: 'Staff Projects',
      text:
        'See when staff is overworked or underworked, and plan projects accordingly.',
      imgSrc: '/images/home_staffing.png',
      imgAlt:
        'Staffing Utilization and Planning Tools for Architects and Designers',
      short: 'Plan Project Staffing',
    },
    {
      id: 'feature-profits',
      headline: 'Realtime Profits',
      text:
        'Instantly understand when a project is going over budget, and when it is right on target.',
      imgSrc: '/images/home_profit.png',
      imgAlt: 'Realtime profits by project for Architects and Designers',
      short: 'See Realtime Profitability',
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          className={classes.banner}
        >
          <Grid item xs={12} md={6}>
            <Grid container direction="row" className={classes.title}>
              <Typography variant="h2" align="left">
                Design Mainframe
              </Typography>
              <Typography variant="h6" align="right" className={classes.beta}>
                beta
              </Typography>
            </Grid>
            <Typography variant="h5" align="left">
              A simple suite of automated tools to help small architecture and
              design studios become more profitable and sustainable.
            </Typography>
            <div className={classes.listWrapper}>
              {features.map(feature => (
                <Typography
                  align="left"
                  variant="h5"
                  key={feature.id}
                  className={classes.link}
                >
                  • <a href={'#' + feature.id}>{feature.short}</a>
                </Typography>
              ))}
            </div>
          </Grid>

          <Grid item container justifyContent="center" xs={12} md={6}>
            <Grid item>
              <Paper
                // variant="outlined"
                className={classes.paper}
                elevation={8}
              >
                {submitStatus === 'UNSUBMITTED' && (
                  <React.Fragment>
                    <Typography variant="h5">
                      Start building a more profitable studio.
                    </Typography>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      className={classes.grid}
                    >
                      <Grid item>
                        <TextField
                          id="email"
                          value={email}
                          label="email"
                          typ="email"
                          onChange={handleEmailChange}
                          variant="outlined"
                          helperText={helperText}
                          error={Boolean(helperText)}
                          size="small"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          variant="outlined"
                          className={classes.submitButton}
                        >
                          Get Started
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" className={classes.subTagline}>
                      Sign up now for a free 3 month trial
                    </Typography>
                    <Typography variant="body2">
                      while we're in beta mode.
                    </Typography>
                  </React.Fragment>
                )}
                {submitStatus === 'LOADING' && (
                  <Loading noFullScreen size={50} />
                )}
                {submitStatus === 'SUBMITTED' && (
                  <Typography variant="h5">
                    We'll be in touch shortly!
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          className={classes.banner}
        >
          <Grid item xs={12} className={classes.textBanner}>
            <Typography variant="h1" align="left" className={classes.title}>
              Designers Care.
            </Typography>
            <Typography variant="h5" align="left" className={classes.subtitle}>
              We often care so much that we forget to care about ourselves.
            </Typography>
            <Typography variant="h5" align="left" className={classes.subtitle}>
              The most sustainable thing a studio can do is become profitable
              enough to continue to design a better future for years to come.
              Let us help you get there...and stay there.
            </Typography>
          </Grid>
        </Grid>

        {features.map(feature => (
          <Grid
            key={feature.id}
            id={feature.id}
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="center"
            spacing={4}
            className={classes.banner}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h3" align="left" className={classes.title}>
                {feature.headline}
              </Typography>
              <Typography
                variant="h5"
                align="left"
                className={classes.subtitle}
              >
                {feature.text}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper className={classes.paperImg} elevation={8}>
                <img
                  src={feature.imgSrc}
                  alt={features.imgAlt}
                  className={classes.bannerImage}
                />
              </Paper>
            </Grid>
          </Grid>
        ))}

        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          className={classes.footer}
        >
          <Grid item container justifyContent="center" xs={12} md={6}>
            <Grid item>
              <Paper
                // variant="outlined"
                className={classes.paper}
                elevation={8}
              >
                {submitStatus === 'UNSUBMITTED' && (
                  <React.Fragment>
                    <Typography variant="h5">
                      Start building a more profitable studio.
                    </Typography>

                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      className={classes.grid}
                    >
                      <Grid item>
                        <TextField
                          id="email"
                          value={email}
                          label="email"
                          typ="email"
                          onChange={handleEmailChange}
                          variant="outlined"
                          helperText={helperText}
                          error={Boolean(helperText)}
                          size="small"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={handleSubmit}
                          variant="outlined"
                          className={classes.submitButton}
                        >
                          Get Started
                        </Button>
                      </Grid>
                    </Grid>
                    <Typography variant="body2" className={classes.subTagline}>
                      Sign up now for a free 3 month trial
                    </Typography>
                    <Typography variant="body2">
                      while we're in beta mode.
                    </Typography>
                  </React.Fragment>
                )}
                {submitStatus === 'LOADING' && (
                  <Loading noFullScreen size={50} />
                )}
                {submitStatus === 'SUBMITTED' && (
                  <Typography variant="h5">
                    We'll be in touch shortly!
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Home.propTypes = {
  // required for style system to work
  onSubmitEmail: PropTypes.func.isRequired,
};

export default Home;
