import React, { Component } from 'react';
import './App.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from '@apollo/client';

import { HttpLink } from 'apollo-link-http';
import { Router } from 'react-router';
import { Helmet } from 'react-helmet';

import { ThemeProvider } from '@mui/material/styles';

import history from './history';
import theme from './theme';
import AppRoutes from './AppRoutes';

import data from './static/headerData.json';
import { GET_HELLO_WORLD } from './queries';

console.log(theme);

// Apollo Client setup
// console.log(process.env.REACT_APP_GRAPHQL_ENDPOINT);
const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  // link: new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT }),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      // fetchPolicy: 'no-cache',
    },
  },
  // typePolicies:
});

// testing
// client.query({ query: GET_HELLO_WORLD }).then(result => console.log(result));

// setup history and other context
global.context = { history, client };

// console.log(JSON.stringify(data.schema));

class App extends Component {
  render() {
    return (
      <Router history={global.context.history}>
        <ApolloProvider client={client}>
          <div className="App">
            <ThemeProvider theme={theme}>
              <Helmet>
                <meta property="og:title" content={data.title} />

                <meta name="description" content={data.schema.description} />
                <link rel="canonical" href={data.canonical} />

                <meta
                  property="og:description"
                  content={data.schema.description}
                />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={data.canonical} />

                {/* SCHEMA.ORG */}
                <script type="application/ld+json">{`
                  ${JSON.stringify(data.schema)}
                `}</script>

                {/* GOOGLE AUTHENTICATION */}
                <script
                  src="https://apis.google.com/js/platform.js"
                  async
                  defer
                />
                <meta
                  name="google-signin-client_id"
                  content={process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID}
                />

                {/* GOOGLE ANALYTICS */}
                <script
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=G-LRJMC69YQS"
                />
                <script type="application/javascript">{`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID}');
                `}</script>
              </Helmet>

              <AppRoutes />

              {/* <div className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <h2>Portals </h2>
              </div> */}
              {/* <div className="App-User">
              </div> */}
            </ThemeProvider>
          </div>
        </ApolloProvider>
      </Router>
    );
  }
}

export default App;
