import traverse from 'traverse';

const defaultPhaseList = [
  { name: 'Pre-Contract', billType: 'Not Billable' },
  { name: 'Schematic Design', active: false },
  { name: 'Design Development', active: false },
  { name: 'Construction Documents', active: false },
  { name: 'Bidding & Negotiation', active: false },
  { name: 'Construction Administration', active: false },
];

const genPhaseArray = phases => {
  let retArray = [];
  if (phases)
    phases.forEach(phase => {
      retArray.push(new Phase(phase));
    });
  else
    defaultPhaseList.forEach(phase => {
      retArray.push(new Phase(phase));
    });
  // console.log(retArray);
  return retArray;
};

export class Phase {
  constructor(data) {
    ['name', 'id', 'startDate'].forEach(key => {
      this[key] = data && data[key] ? data[key] : '';
    });
    ['fee', 'duration', 'startWeek'].forEach(key => {
      this[key] = data && data[key] ? data[key] : 0;
    });
    // this.billable = data && data.billable !== undefined ? data.billable : true;
    this.billType = data && data.billType ? data.billType : 'Fixed Fee';
    this.active = data && data.active !== undefined ? data.active : true;
    if (data && data.deleted) this.deleted = data.deleted;
    this.resources =
      data && data.resources ? JSON.parse(JSON.stringify(data.resources)) : [];
  }
}

export class Project {
  constructor(data, params) {
    // console.log('project class running...')
    ['id', 'dateCreated', 'name', 'shortName', 'code'].forEach(key => {
      this[key] = data && data[key] ? data[key] : '';
    });

    // number params
    ['profitPercentage', 'constructionBudget', 'multiplier'].forEach(key => {
      this[key] = data && data[key] ? data[key] : 0;
    });
    this.startDate =
      data && data.startDate ? data.startDate : new Date().toISOString();
    this.version = data && data.version ? data.version : 2;
    this.color = data && data.color ? data.color : '#FFFFFF';
    this.orgId = data && data.orgId ? data.orgId : params.orgId;
    this.status = data && data.status ? data.status : 'Proposal';
    this.floorArea = data && data.floorArea ? data.floorArea : 0;
    this.lockedKeys = data && data.lockedKeys ? data.lockedKeys : [];

    // optional params
    ['resourceTypes'].forEach(key => {
      this[key] = data && data[key] ? data[key] : undefined;
    });
    // phases
    this.phases =
      data && data.phases ? genPhaseArray(data.phases) : genPhaseArray();

    // handle new phase startTime param - won't need this eventually
    this.phases.forEach(phase => {
      if (!phase.startDate && this.startDate) {
        const date = new Date();
        date.setDate(new Date(this.startDate).getDate() + 7 * phase.startWeek);
        phase.startDate = date.toISOString();
        delete phase.startWeek;
      }
    });

    // make (& and wash) team
    this.team = [];
    if (data && data.team) this.team = [].concat(data.team);

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

// fix this later
export class ProjectClass extends Project {}

export class WikiEntry {
  constructor(data, params) {
    ['id', 'dateCreated', 'dateUpdated', 'orgId', 'markdown', 'key'].forEach(
      key => {
        this[key] = data && data[key] ? data[key] : '';
      }
    );

    this.locked = data && data.locked ? data.locked : false;
    this.orgId = data && data.orgId ? data.orgId : params.orgId;
    this.createdBy = data && data.createdBy ? data.createdBy : params.userId;
    this.deleted = data && data.deleted ? data.deleted : undefined;

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

export class Task {
  constructor(data, params) {
    [
      'id',
      'dateCreated',
      'createdBy',
      'title',
      'details',
      'projectId',
      'phaseId',
      'orgId',
      'dueDate',
    ].forEach(key => {
      this[key] = data && data[key] ? data[key] : '';
    });

    this.status = data && data.status ? data.status : 0;
    this.priority = data && data.priority ? data.priority : 0;

    // optional params
    ['assignee', 'private'].forEach(key => {
      this[key] = data && data[key] ? data[key] : undefined;
    });

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

export class LeadPost {
  constructor(data, params) {
    [
      'title',
      'description',
      'score',
      'keywords',
      'names',
      'url',
      'error',
      'text',
      'dateCreated',
      'dateUpdated',
      'orgId',
      'userId',
      'id',
    ].forEach(key => {
      this[key] = data && data[key] ? data[key] : '';
    });
    this.deleted = data && data.deleted ? data.deleted : undefined;

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

export class SiteReport {
  constructor(data, params) {
    this.id = data && data.id ? data.id : '';
    this.createdBy = data && data.createdBy ? data.createdBy : params.userId;
    this.orgId = data && data.orgId ? data.orgId : params.orgId;
    this.date = data && data.date ? data.date : new Date().toISOString();
    this.dateCreated = data && data.dateCreated ? data.dateCreated : '';
    this.dateUpdated = data && data.dateUpdated ? data.dateUpdated : '';
    this.projectId = data && data.projectId ? data.projectId : params.projectId;
    this.markdown = data && data.markdown ? data.markdown : '';
    this.siteProgresses =
      data && data.siteProgresses ? data.siteProgresses : [];

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

export class SiteProgress {
  constructor(data, params) {
    this.code = data && data.code ? data.code : '';
    this.progress = data && data.progress ? data.progress : 0;
    this.markdown = data && data.markdown ? data.markdown : '';
    this.images = data && data.images ? data.images : [];

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

export class Hours {
  constructor(data, params) {
    this.id = data.id ? data.id : '';
    this.sunday = data.sunday;
    this.userId = data.userId ? data.userId : '';
    this.orgId = data && data.orgId ? data.orgId : params.orgId;
    this.rows = [];
    this.submitted = data && data.submitted ? data.submitted : false;

    // wash rows
    if (data && data.rows) {
      data.rows.forEach((row, rInd) => {
        this.rows.push({
          projectId: row.projectId,
          phaseId: row.phaseId,
          hours: [],
        });

        // wash hours
        row.hours.forEach((hourEntry, hInd) => {
          this.rows[rInd].hours.push({
            count: hourEntry.count,
            note: hourEntry.note,
          });
        });
      });
    }

    // remove residue
    const washedObj = removeResidue(this, params);

    // copy to this
    Object.keys(washedObj).forEach(key => {
      this[key] = washedObj[key];
    });
  }
}

export function removeResidue(obj, params) {
  // remove residue
  traverse(obj).forEach((child, index) => {
    // remove null values, empty strings, and make extensible
    if (child && typeof child === 'object') {
      if (!Object.isExtensible(child))
        child = JSON.parse(JSON.stringify(child));
      Object.keys(child).forEach(key => {
        if (child[key] === null) {
          // console.log(key);
          delete child[key];
        }
        if (params && params.removeEmptyStrings && child[key] === '')
          delete child[key];
        // if (params && params.makeExtensible)
        //   child[key] = JSON.parse(JSON.stringify(child[key]));
      });
    }
    // if (child && child.freelance) console.log(child);
  });

  return obj;
}

export const serializeObject = obj => {
  return JSON.parse(JSON.stringify(obj));
};

export const PhaseBillingTypes = [
  { id: 'Fixed Fee', name: 'Fixed Fee' },
  { id: 'Hourly', name: 'Hourly' },
  { id: 'Hourly Not To Exceed', name: 'Hourly Not To Exceed' },
  { id: 'Not Billable', name: 'Not Billable' },
];

export const ProjectStatusTypes = [
  { id: 'Active', name: 'Active' },
  { id: 'Complete', name: 'Complete' },
  { id: 'Inactive', name: 'Inactive' },
  { id: 'Indefinite', name: 'Indefinite' },
  { id: 'Proposal', name: 'Proposal' },
];
