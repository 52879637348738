import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';

import theme from '../../theme';

const useStyles = makeStyles(() => ({
  progress: {
    margin: theme.spacing(2),
  },
  fullScreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 64px)',
  },
}));

function Loading(props) {
  let { noFullScreen, size } = props;
  const classes = useStyles();

  if (!size) size = 200;
  const thickness = (200 / size) * 0.5;

  return (
    <div className={!noFullScreen ? classes.fullScreen : undefined}>
      <CircularProgress
        className={classes.progress}
        size={size}
        thickness={thickness}
      />
    </div>
  );
}

Loading.propTypes = {
  noFullScreen: PropTypes.bool,
  size: PropTypes.number,
};

export default Loading;
