import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  currentSiteReport: null,
  loading: false,
};

export const siteReportsSlice = createSlice({
  name: 'siteReports',
  initialState,
  reducers: {
    addSiteReport: state => {},
    deleteSiteReport: state => {},
    setSiteReports: (state, action) => {
      state.items = action.payload;
    },
    setCurrentSiteReport: (state, action) => {
      state.currentSiteReport = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addSiteReport,
  deleteSiteReport,
  setSiteReports,
  setLoading,
  setCurrentSiteReport,
} = siteReportsSlice.actions;

export default siteReportsSlice.reducer;
