import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation, useLazyQuery } from '@apollo/client';
import {
  setCurrentSiteReport,
  setSiteReports,
  setLoading,
} from '../redux/siteReports/siteReportsSlice';
// import { setProjects, setLoading } from '../redux/projects/projectsSlice';
// import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
// import { QUERY_ITEMS, MUTATE_ITEM } from '../queries';
// import { getToken } from '../actions/UserActions';
import { SiteReport, removeResidue, serializeObject } from '../classes';
import { getToken } from '../actions/UserActions';
import { MUTATE_ITEM, QUERY_ITEMS } from '../queries';
import { digestMutateResponse } from '../actions/BaseActions';
import { setSnack } from '../redux/messages/messagesSlice';
import history from '../history';

const useSiteReports = () => {
  const {
    user: { value: user },
    projects: { currentProject },
    siteReports: { items: siteReports, loading, currentSiteReport },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const [
    mutateItem,
    { loading: mutateLoading, called: mutateCalled },
  ] = useMutation(MUTATE_ITEM);

  // Query
  const [
    fetchSiteReports,
    { called, loading: lazyLoading, data, refetch },
  ] = useLazyQuery(QUERY_ITEMS, {
    variables: {
      input: {
        token: getToken(),
        requestedItems: ['siteReports'],
        // projectId: currentProject?.id,
      },
    },
  });

  // update Site Reports on change
  useEffect(() => {
    if (data?.queryItems?.siteReports) {
      dispatch(setSiteReports(data?.queryItems.siteReports));
    }
  }, [data]);

  // trigger query on project change
  // useEffect(() => {
  //   console.log(siteReports);
  //   // return;
  //   // if (!currentProject?.id) return;
  //   if (
  //     siteReports.length > 0
  //     // siteReports[0].projectId === currentProject.id
  //   )
  //     // return;
  //     fetchSiteReports();
  // }, [siteReports]);

  // Loading
  useEffect(() => {
    if (!called) return; // removes instances that never call it from the hook
    if (lazyLoading === loading) return;
    dispatch(setLoading(lazyLoading));
  }, [lazyLoading, loading, called]);

  const updateCurrentSiteReport = siteReport => {
    // sort progresses
    const newSiteReport = serializeObject({
      ...siteReport,
      siteProgresses: siteReport.siteProgresses.sort((a, b) =>
        a.code < b.code ? -1 : a.code > b.code ? 1 : 0
      ),
    });
    dispatch(setCurrentSiteReport(newSiteReport));

    updateSiteReport(siteReport);
  };

  const addSiteReport = async () => {
    const newSiteReport = new SiteReport(
      {
        createdBy: user.id,
        orgId: user.orgId,
        projectId: currentProject.id,
      },
      {}
    );
    const savedSiteReport = await updateSiteReport(newSiteReport);
    history.push(`/site-reports/${currentProject.slug}/${savedSiteReport.id}`);
  };

  const updateSiteReport = async siteReport => {
    console.log('update site report not setup yet');
    console.log(siteReport);

    const isNew = !siteReport.id;

    const input = {
      token: getToken(),
      siteReport: removeResidue(siteReport),
      action: isNew ? 'ADD' : 'MUTATE',
    };

    const resp = await mutateItem({
      variables: { input },
    });

    // handle messaging
    const { snackObj, item: savedSiteReport } = digestMutateResponse(
      resp,
      'siteReport'
    );
    console.log(resp);
    if (snackObj) dispatch(setSnack(snackObj));

    // handle error
    if (!savedSiteReport)
      dispatch(
        setSnack({ status: 'ERROR', message: 'Error saving Site Report' })
      );
    else {
      dispatch(
        setSiteReports([
          ...siteReports.filter(a => a.id !== savedSiteReport.id),
          savedSiteReport,
        ])
      );
      return savedSiteReport;
    }

    // create current projects
    //   useEffect(() => {
    //     if (!user || !items) return;
    //     const filterStatuses = user.roles.includes('admin')
    //       ? ['inactive', 'complete']
    //       : ['inactive', 'complete', 'proposal'];

    //     setCurrentProjects(
    //       items
    //         .filter(a => {
    //           if (filterStatuses.includes(a.status.toLowerCase())) return false;
    //           return true;
    //         })
    //         .sort((a, b) => a.code.localeCompare(b.code))
    //     );
    //   }, [user, items]);
  };

  return {
    items: siteReports,
    currentSiteReport,
    setCurrentSiteReport: report => dispatch(setCurrentSiteReport(report)),
    updateCurrentSiteReport,
    addSiteReport,
    loading,
    called,
    fetchSiteReports,
    // error,
  };
};

export default useSiteReports;
