import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_ITEMS, MUTATE_ITEM } from '../queries';
import { getToken } from '../actions/UserActions';
import { setOrganization } from '../redux/organization/organizationSlice';

const useOrganization = () => {
  const { value } = useSelector(state => state.organization);
  const dispatch = useDispatch();

  // Query
  const [
    fetchOrganization,
    { called, loading: lazyLoading, data, refetch },
  ] = useLazyQuery(QUERY_ITEMS, {
    variables: {
      input: {
        token: getToken(),
        requestedItems: ['organization'],
      },
    },
  });

  // update organization on change
  useEffect(() => {
    if (data?.queryItems?.organization) {
      dispatch(setOrganization(data.queryItems.organization));
    }
  }, [data]);

  // Loading - skipping for now
  //   useEffect(() => {
  //     if (!called) return; // removes instances that never call it from the hook
  //     if (lazyLoading === loading) return;
  //     // dispatch(setLoading(lazyLoading));
  //   }, [lazyLoading, loading, called]);

  return {
    value,
    fetchOrganization,
    // loading,
  };
};

export default useOrganization;
