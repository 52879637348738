// setup global handleSignIn
// global.handleSignIn = async googleUser => {
//   console.log('handleSignIn ran');
//   const { profile, googleToken } = await onSignIn(googleUser);

//   global.verifyUser({ profile, googleToken });
// };

// const { history } = global.context;

// handle user content
export async function onSignIn(googleUser) {
  var profile = googleUser.getBasicProfile();
  // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
  // console.log('Name: ' + profile.getName());
  // console.log('Image URL: ' + profile.getImageUrl());
  // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
  const googleToken = googleUser.getAuthResponse().id_token;

  return { profile, googleToken };
}

export function showSignIn() {
  console.log('showSignIn');
}

// handle sign out
export function signOut() {
  // log out of google if using it
  if (global.gapi && global.gapi.auth2) {
    var auth2 = global.gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      // console.log('deleting token');
      deleteToken();
      // console.log(getToken());
      global.context.history.push('/');
      console.log('signed out');
    });

    // otherwise delete token
  } else {
    console.log('deleting token');
    deleteToken();
    console.log(getToken());
    global.context.history.push('/');
    console.log('signed out');
  }
  window.location.reload(false);
}

// set token in local storage
export function setToken(token) {
  global.localStorage.setItem('token', token);
}

export function deleteToken() {
  global.localStorage.removeItem('token');
}

// get token from storage
export function getToken() {
  if (global.localStorage) return global.localStorage.getItem('token');
  else return undefined;
}
