import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  snackObj: null,
  confirmMessage: '',
  // onConfirm: () => {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    setSnack: (state, action) => {
      if (!action.payload) return; // fix this later
      const { status, message } = action.payload;
      state.snackObj = {
        status,
        message,
      };
    },
    clearSnack: state => {
      state.snackObj = null;
    },
    setConfirmMessage: (state, action) => {
      state.confirmMessage = action.payload;
    },
    clearConfirmMessage: state => {
      state.confirmMessage = '';
    },
    // setOnConFirm: (state, action) => {
    //   state.onConfirm = action.payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSnack,
  clearSnack,
  setConfirmMessage,
  clearConfirmMessage,
  // setOnConFirm,
} = messagesSlice.actions;

export default messagesSlice.reducer;
