import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_ITEMS, MUTATE_ITEM } from '../queries';
import { getToken } from '../actions/UserActions';
import { setUsers, setLoading } from '../redux/users/usersSlice';

const useUsers = () => {
  const { items, loading } = useSelector(state => state.users);
  const dispatch = useDispatch();

  // Query
  const [
    fetchUsers,
    { called, loading: lazyLoading, data, refetch },
  ] = useLazyQuery(QUERY_ITEMS, {
    variables: {
      input: {
        token: getToken(),
        requestedItems: ['users'],
      },
    },
  });

  // update users on change
  useEffect(() => {
    if (data?.queryItems?.users) {
      dispatch(setUsers(data.queryItems.users));
    }
  }, [data]);

  // Loading
  useEffect(() => {
    if (!called) return; // removes instances that never call it from the hook
    if (lazyLoading === loading) return;
    dispatch(setLoading(lazyLoading));
  }, [lazyLoading, loading, called]);

  return {
    items,
    activeUsers: items.filter(a => !a.inactive && a.roles?.length > 0),
    fetchUsers,
    loading,
    // error,
  };
};

export default useUsers;
