import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  setProjects,
  setLoading,
  setCurrentProject,
} from '../redux/projects/projectsSlice';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_ITEMS, MUTATE_ITEM } from '../queries';
import { getToken } from '../actions/UserActions';

const useProjects = () => {
  const {
    user: { value: user },
    projects: { items, loading, currentProject },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  const [currentProjects, setCurrentProjects] = useState([]);

  // Query
  const [
    fetchProjects,
    { called, loading: lazyLoading, data, refetch },
  ] = useLazyQuery(QUERY_ITEMS, {
    variables: {
      input: {
        token: getToken(),
        requestedItems: ['projects'],
      },
    },
  });

  // update projects on change
  useEffect(() => {
    if (data?.queryItems?.projects) {
      dispatch(setProjects(data?.queryItems.projects));
    }
  }, [data]);

  // Loading
  useEffect(() => {
    if (!called) return; // removes instances that never call it from the hook
    if (lazyLoading === loading) return;
    dispatch(setLoading(lazyLoading));
  }, [lazyLoading, loading, called]);

  const updateProject = task => {};
  const addProject = task => {};

  // create current projects
  useEffect(() => {
    if (!user || !items) return;
    const filterStatuses = user.roles.includes('admin')
      ? ['inactive', 'complete']
      : ['inactive', 'complete', 'proposal'];

    setCurrentProjects(
      items
        .filter(a => {
          if (filterStatuses.includes(a.status.toLowerCase())) return false;
          return true;
        })
        .sort((a, b) => a.code.localeCompare(b.code))
    );
  }, [user, items]);

  // update current project
  const handleSetCurrentProject = project => {
    dispatch(setCurrentProject(project));
  };

  return {
    items,
    currentProjects,
    currentProject,
    updateProject,
    addProject,
    loading,
    fetchProjects,
    setCurrentProject: handleSetCurrentProject,
    // error,
  };
};

export default useProjects;
