// reference https://material-ui.com/customization/default-theme/

import { createTheme } from '@mui/material/styles';

// Material UI Theme setup
let theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          outline: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '2px solid #000',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#000',
        },
        // label: {},
        outlined: {
          border: '1px solid rgba(0, 0, 0, 0.23)',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: '2px solid #000',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        footer: {
          fontSize: '0.875rem',
        },
        head: {
          fontWeight: 700,
        },
        stickyHeader: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: '2px solid #000',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255,255,255,0.65)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          color: '#000',
          border: '1px solid #000',
        },
        arrow: {
          color: '#000',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: '#fff',
          color: '#000',
          border: '1px solid #000',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '& > .MuiToolbar-root': {
            paddingLeft: '14px',
            paddingRight: '14px',
          },
        },
        colorPrimary: {
          color: '#000',
          backgroundColor: '#fff',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        dense: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '000',
          border: '2px solid #000',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: '#000',
        },
      },
    },
    // MuiBadge: {
    //   styleOverrides: {
    //     badge: {
    //       backgroundColor: 'rgba(0,0,0,0.35)',
    //     },
    //   },
    // },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0 14px',
        },
        content: {
          margin: '8px 0',
          // padding: '0 -2px'
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#000',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        label: {
          overflow: 'visible',
        },
      },
      defaultProps: {
        size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
        size: 'small',
      },
    },
  },
  palette: {
    primary: {
      main: 'rgba(0, 0, 0, 0.54)',
      error: 'rgba(255,120,120,1)',
    },
    //   secondary: {
    //     main: '#B2B2B2',
    //   },
  },
  typography: {
    fontFamily: [
      'Cabin',
      'sans-serif',
      // 'IBM Plex Mono',
      'B612 Mono',
      // //     'Space Mono', // add to index.css
      'monospace',
    ].join(','),
    // '@media (max-width:600px)': {
    //   fontSize: '0.5rem',
    // },
  },
  custom: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderDataGrid: '1px solid rgba(224, 224, 224)',
    colorSet: [
      '#FFFFFF',
      '#9C9C9C',
      '#A7226E',
      '#EC2049',
      '#F26B38',
      '#F7DB4F',
      '#2F9599',
    ],
  },
});

// theme.typography.h2[theme.breakpoints.down('md')] = { fontSize: '4rem'};
theme.typography.h5[theme.breakpoints.down('md')] = { fontSize: '1.2rem' };
theme.typography.h1[theme.breakpoints.down('md')] = { fontSize: '5rem' };

export default theme;
