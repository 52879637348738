import React, { useEffect } from 'react';
// import PropTypes from 'prop-types'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  getToken,
  deleteToken,
  setToken,
  // signOut,
  // onSignIn,
} from './actions/UserActions';
import { GET_USER } from './queries';
// import { usePrevious } from './actions/BaseActions'
import HomeContainer from './containers/HomeContainer';
import Loading from './components/Misc/Loading';
import useData from './hooks/useData';
// import NavBar from './components/NavBar'

/**
 * Root container and Redux router.
 */

// for code splitting - https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/code-splitting.md

// const AdminContainer = loadable(() => import('./containers/AdminContainer'))
const ProjectContainer = loadable(() =>
  import('./containers/ProjectContainer')
);
const StaffingContainer = loadable(() =>
  import('./containers/StaffingContainer')
);
const AdminDashboardContainer = loadable(() =>
  import('./containers/AdminDashboardContainer')
);
const EmployeeDashboardContainer = loadable(() =>
  import('./containers/EmployeeDashboardContainer')
);
const TimesheetContainer = loadable(() =>
  import('./containers/TimesheetContainer')
);
const WikiContainer = loadable(() => import('./containers/WikiContainer'));
const ProjectsContainer = loadable(() =>
  import('./containers/ProjectsContainer')
);
const PoliciesContainer = loadable(() =>
  import('./containers/PoliciesContainer')
);
const FinancesContainer = loadable(() =>
  import('./containers/FinancesContainer')
);
const LeadsContainer = loadable(() => import('./containers/LeadsContainer'));

const TasksContainer = loadable(() => import('./containers/TasksContainer'));

const SiteReportsContainer = loadable(() =>
  import('./containers/SiteReportsContainer')
);

// Redux Router.
// https://reacttraining.com/react-router/web/api/Route/Route-props
// NOTE: Each component extracts the router path params passed to props.

const AppRoutes = () => {
  const location = useLocation();
  const { user, setUser } = useData();

  // Authorization
  const token = getToken();

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { input: { token } },
    skip: !token,
  });

  const [getUserLazy, lazyResp] = useLazyQuery(GET_USER);

  if (error) {
    // console.error(error)
  }

  useEffect(() => {
    if (data && data.getUser) {
      if (data.getUser.errors || !data.getUser.user) {
        console.log('User has no Authorization');
        setUser(null);
        deleteToken();
      } else {
        setUser(data.getUser.user);
      }
    }
  }, [data]);

  // create token and reload
  if (lazyResp.data) {
    // console.log(lazyResp)
    if (lazyResp.data.getUser.errors) {
      console.error(lazyResp.data.getUser.errors[0]);
      global.alert(
        "Your email hasn't been authorized yet. Please sign up for our beta, and we'll let you knwo when we are ready for you!"
      );
      // global.location.reload(false)
    }
    if (lazyResp.data.getUser && lazyResp.data.getUser.token) {
      console.log(lazyResp.data.getUser);
      if (!token) {
        setToken(lazyResp.data.getUser.token);
        global.location.reload(false);
      } else {
        user = lazyResp.data.getUser.user;
        // console.log(user)
      }
      // setToken(lazyResp.data.getUser.token)
      // global.location.reload(false)
    }
  }

  const handleGoogleResponse = response => {
    if (!response || !response.profileObj) return;
    const prof = response.profileObj;
    let input = {
      googleToken: response.tokenId,
      displayName: prof.name,
      email: prof.email.toLowerCase(),
      profilePicture: prof.imageUrl,
    };
    getUserLazy({ variables: { input } });
  };

  // define function globally for access
  global.context.handleGoogleResponse = handleGoogleResponse;

  const getAuth = (role, bvOnly) => {
    if (!bvOnly) {
      if (token && user && user.roles.includes(role)) return true;
      return false;
    } else {
      if (
        token &&
        user &&
        user.roles.includes(role) &&
        user.orgId === 'e46417f0-63fb-4218-a70e-a13a6ff93283'
      )
        return true;
      return false;
    }
  };

  // no token - immediately return and redirect to home
  if (!token)
    return (
      <Switch>
        <Route exact path={'/'}>
          <HomeContainer />
        </Route>
        <Redirect to={'/'} />
      </Switch>
    );

  // token exists, now wait for user
  if (!user) return <Loading />;

  // we have a token, let's go through this
  return (
    <Switch>
      <Route
        exact
        path={'/'}
        render={() => {
          if (getAuth('admin')) return <AdminDashboardContainer user={user} />;
          else if (getAuth('employee'))
            return <EmployeeDashboardContainer user={user} />;
          else return <HomeContainer />;
        }}
      />

      <Route path={'/staffing'}>
        {getAuth('admin') ? (
          <StaffingContainer user={user} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route
        path={'/timesheet/:id'}
        render={props => {
          if (getAuth('employee'))
            return (
              <TimesheetContainer user={user} slug={props.match.params.id} />
            );
          return <Redirect to="/" />;
        }}
      />

      <Route path={'/timesheet'}>
        {getAuth('employee') ? (
          <TimesheetContainer user={user} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route path={'/wiki'}>
        {getAuth('employee') ? (
          <WikiContainer user={user} location={location} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route path={'/leads'}>
        {getAuth('employee', true) ? (
          <LeadsContainer user={user} location={location} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route path={'/tasks'}>
        {getAuth('employee') ? (
          <TasksContainer user={user} location={location} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route
        path={'/projects/:id'}
        render={props => {
          if (getAuth('admin'))
            return (
              <ProjectContainer user={user} slug={props.match.params.id} />
            );
          return <Redirect to="/" />;
        }}
      />

      <Route path={'/projects'}>
        {getAuth('admin') ? (
          <ProjectsContainer user={user} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route
        path={'/site-reports/:id'}
        render={props => {
          if (getAuth('employee'))
            return (
              <SiteReportsContainer
                user={user}
                slug={props.match.params.id}
                location={location}
              />
            );
          return <Redirect to="/" />;
        }}
      />

      <Route
        path={'/site-reports'}
        render={props => {
          if (getAuth('employee')) return <SiteReportsContainer user={user} />;
          return <Redirect to="/" />;
        }}
      />

      <Route path={'/finances'}>
        {getAuth('admin') ? (
          <FinancesContainer user={user} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      <Route path={'/policies'}>
        {getAuth('employee') ? (
          <PoliciesContainer user={user} />
        ) : (
          <Redirect to="/" />
        )}
      </Route>

      {/* <Route path={'/finances'}>
        <AuthContainer>
          <FinancesContainer />
        </AuthContainer>
      </Route> */}

      {/* Catch and redirect unknown urls */}
      <Redirect to={'/'} />
    </Switch>
  );
};

export default AppRoutes;
